<div class="bg_form">
    <div class="bg_form-container" *ngIf="!isSend">
        <img src="../img/logo.svg" alt="">
        <h3>Recuperação de Senha</h3>

        <form class="login-form" #forgotForm="ngForm" (ngSubmit)="forgotPassword(forgotForm.value)">

            <div class="textb">
                <input type="text" ngModel name="username" required="required">
                <div class="placeholder">Matrícula cadastrada</div>
            </div>

            <div class="btn-form-login">
                <input type="submit" value="Solicitar recuperação">
            </div>
        </form>
    </div>
    <div class="bg_form-container" *ngIf="isSend">
        <img class="logo" src="../../../assets/images/logo.svg" alt="">
        <h3>Feito com sucesso!</h3>

        <form action="#" class="login-form">

            <div class="box-confirmation">
                <img class="iconePositive" src="../../../assets/images/positive.svg" alt="">
                <p class="text-verificaEmail">Agora verifique seu e-mail.</p>
            </div>

            <div class="btn-form-login">
                <input type="button" routerLink="/login" value="Voltar para o login">
            </div>
        </form>
    </div>
</div>