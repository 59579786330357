import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AccountsService } from '../../../services/accounts/accounts.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class AccountEditComponent implements OnInit {
  editAccountForm: FormGroup;

  constructor(
    private accountsService: AccountsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AccountEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.warn('dentro do model >> ', this.data);

    this.editAccountForm = this.fb.group({
      creditLimit: new FormControl(this.data.creditLimit, [Validators.required])
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  update(): void {
    if (!this.editAccountForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.editAccountForm.markAllAsTouched();

      return;
    }

    let paylod = this.editAccountForm.value;
    this.data.creditLimit = paylod.creditLimit;

    this.accountsService.edit(this.data).subscribe((json) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Limite atualizado com sucesso.'
      }).then(() => this.dialogRef.close());
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

}
