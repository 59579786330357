<div class="section">

    <div class="section__form">
        <form class="first-colum" [formGroup]="employeeForm" (ngSubmit)="edit()">
            <div class="form">
                <label>Nome Completo *</label><br />
                <input type="text" formControlName="name" name="name" id="name"
                    [ngClass]="{alert: employeeForm.controls['name'].touched && employeeForm.controls['name'].invalid}" />
            </div>

            <div class="form">
                <label>Cpf *</label><br />
                <input type="text" formControlName="cpf" name="cpf" id="cpf" mask="000.000.000-00"
                    [ngClass]="{alert: employeeForm.controls['cpf'].touched && employeeForm.controls['cpf'].invalid}" />
            </div>

            <div class="form">
                <label>Matrícula *</label><br />
                <input type="text" formControlName="registration" name="registration" id="registration"
                    [ngClass]="{alert: employeeForm.controls['registration'].touched && employeeForm.controls['registration'].invalid}" />
            </div>

            <div class="form">
                <label>E-mail *</label><br />
                <input type="text" formControlName="email" name="email" id="email"
                    [ngClass]="{alert: employeeForm.controls['email'].touched && employeeForm.controls['email'].invalid}" />
            </div>

            <div class="form">
                <label>Celular *</label><br />
                <input type="text" formControlName="cellphone" name="cellphone" id="cellphone" mask="(00) 00000-0000"
                    [ngClass]="{alert: employeeForm.controls['cellphone'].touched && employeeForm.controls['cellphone'].invalid}" />
            </div>

            <div class="form">
                <label>Perfil *</label><br />
                <select formControlName="profile" name="profile" id="profile"
                    [ngClass]="{alert: employeeForm.controls['profile'].touched && employeeForm.controls['profile'].invalid}">
                    <option value="" disabled="disabled">Escolha a opção</option>
                    <option value="Admin">Administrador</option>
                    <option value="Operator">Operador</option>
                    <option value="POS">POS</option>
                    <option value="Common">Comum</option>
                </select>
            </div>

            <div class="form">
                <label>Estabelecimento *</label><br />
                <select formControlName="establishment" name="establishment" id="establishment"
                    [ngClass]="{alert: employeeForm.controls['establishment'].touched && employeeForm.controls['establishment'].invalid}">
                    <option value="{{item._id}}" *ngFor="let item of establishments">{{item.name}}</option>
                </select>
            </div>

            <div class="form">
                <label>Senha provisória *</label><br />
                <input type="password" formControlName="password" name="password" id="password"
                    [ngClass]="{alert: employeeForm.controls['password'].touched && employeeForm.controls['password'].invalid}" />
            </div>

            <div class="form">
                <label>Nome do Endereço</label><br />
                <input type="text" formControlName="addressName" name="addressName" id="addressName"
                    [ngClass]="{alert: employeeForm.controls['addressName'].touched && employeeForm.controls['addressName'].invalid}" />
            </div>

            <div class="form">
                <label>Tipo de Endereço *</label><br />
                <select formControlName="addressType" name="addressType" id="addressType"
                    [ngClass]="{alert: employeeForm.controls['addressType'].touched && employeeForm.controls['addressType'].invalid}">
                    <option value="Residential">Residencial</option>
                    <option value="Store">Comercial</option>
                    <option value="Other">Outro</option>
                </select>
            </div>

            <div class="form">
                <label>CEP *</label><br />
                <input type="text" formControlName="postalCode" name="postalCode" id="postalCode" (blur)="searchCep()"
                    mask="00000-000" [ngClass]="{alert: employeeForm.controls['postalCode'].touched && employeeForm.controls['postalCode'].invalid}" />
            </div>

            <div class="form">
                <label>Rua *</label><br />
                <input type="text" formControlName="logradouro" name="logradouro" id="logradouro"
                    [ngClass]="{alert: employeeForm.controls['logradouro'].touched && employeeForm.controls['logradouro'].invalid}" />
            </div>

            <div class="form">
                <label>Nº *</label><br />
                <input type="text" formControlName="number" name="number" id="number"
                    [ngClass]="{alert: employeeForm.controls['number'].touched && employeeForm.controls['number'].invalid}" />
            </div>

            <div class="form">
                <label>Complemento</label><br />
                <input type="text" formControlName="complement" name="complement" id="complement" />
            </div>

            <div class="form">
                <label>Bairro *</label><br />
                <input type="text" formControlName="neighborhood" name="neighborhood" id="neighborhood"
                    [ngClass]="{alert: employeeForm.controls['neighborhood'].touched && employeeForm.controls['neighborhood'].invalid}" />
            </div>

            <div class="form">
                <label>Cidade *</label><br />
                <input type="text" formControlName="city" name="city" id="city"
                    [ngClass]="{alert: employeeForm.controls['city'].touched && employeeForm.controls['city'].invalid}" />
            </div>

            <div class="form">
                <label>Estado *</label><br />
                <input type="text" formControlName="state" name="state" id="state"
                    [ngClass]="{alert: employeeForm.controls['state'].touched && employeeForm.controls['state'].invalid}" />
            </div>

            <div class="form">
                <input type="submit" value="Editar cadastro" />
            </div>

        </form>
    </div>

</div>