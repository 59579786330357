import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SidebarComponent } from '../app/components/sidebar/sidebar.component';
import { HomeComponent } from './views/home/home.component';

import { CooperativeListComponent } from './views/cooperative/list/list.component';
import { CooperativeNewComponent } from './views/cooperative/new/new.component';
import { CooperativeEditComponent } from './views/cooperative/edit/edit.component';

import { EmployeeListComponent } from './views/employee/list/list.component';
import { EmployeeNewComponent } from './views/employee/new/new.component';
import { EmployeeEditComponent } from './views/employee/edit/edit.component';

import { EstablishmentListComponent } from './views/establishment/list/list.component';
import { EstablishmentNewComponent } from './views/establishment/new/new.component';
import { EstablishmentEditComponent } from './views/establishment/edit/edit.component';

import { AccountListComponent } from './views/account/list/list.component';
import { AccountNewComponent } from './views/account/new/new.component';
import { AccountEditComponent } from './views/account/edit/edit.component';

import { RequestListComponent } from './views/request/list/list.component';
import { RequestEditComponent } from './views/request/edit/edit.component';

import { ProfileComponent } from './views/profile/profile.component';

import { LoginComponent } from './views/login/login.component';

import { ForgotpasswordComponent } from './views/forgotpassword/forgotpassword.component';

import { OrderListComponent } from './views/order/list/list.component';


import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LayoutModule } from '@angular/cdk/layout';
import { EmptyComponent } from './components/layouts/empty/empty.component';
import { FullComponent } from './components/layouts/full/full.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { MatSpinnerOverlayComponent } from './components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LoadingService } from './services/loading/loading.service';
import { LoaderInterceptor } from '../app/interceptors/loader-interceptor';

import { CookieService } from 'ngx-cookie-service';
import { ExcelService } from './components/excel/excel.service';

import { InfiniteScrollModule  } from 'ngx-infinite-scroll';
import { CooperativeAccountComponent } from './views/cooperative/cooperative-account/cooperative-account.component';

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { AccountsComponent } from './views/cooperative/accounts/accounts.component';
import { ListHolderComponent } from './views/account/list-holder/list-holder.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CooperativeListComponent,
    CooperativeNewComponent,
    CooperativeEditComponent,
    EmployeeListComponent,
    EmployeeNewComponent,
    EmployeeEditComponent,
    EstablishmentListComponent,
    EstablishmentNewComponent,
    EstablishmentEditComponent,
    AccountListComponent,
    AccountNewComponent,
    AccountEditComponent,
    LoginComponent,
    ForgotpasswordComponent,
    SidebarComponent,
    EmptyComponent,
    FullComponent,
    MatSpinnerOverlayComponent,
    RequestListComponent,
    RequestEditComponent,
    ProfileComponent,
    CooperativeAccountComponent,
    AccountsComponent,
    OrderListComponent,
    ListHolderComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatTreeModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    LayoutModule,
    ReactiveFormsModule,
    InfiniteScrollModule,     
    NgxMaskModule.forRoot()
  ],
  providers: [
    LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    CookieService,
    ExcelService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
