<div class="section">

    <div class="section__form">
        <form class="first-colum" [formGroup]="accountForm" (ngSubmit)="save()">
            <div class="form">
                <label>Nome Completo *</label><br />
                <input type="text" formControlName="name" name="name" id="name"
                    [ngClass]="{alert: accountForm.controls['name'].touched && accountForm.controls['name'].invalid}" />
            </div>

            <div class="form">
                <label>Cpf *</label><br />
                <input type="text" formControlName="cpf" name="cpf" id="cpf" mask="000.000.000-00"
                    [ngClass]="{alert: accountForm.controls['cpf'].touched && accountForm.controls['cpf'].invalid}" />
            </div>

            <div class="form">
                <label>Matrícula *</label><br />
                <input type="text" formControlName="registration" name="registration" id="registration"
                    [ngClass]="{alert: accountForm.controls['registration'].touched && accountForm.controls['registration'].invalid}" />
            </div>

            <div class="form">
                <label>E-mail *</label><br />
                <input type="text" formControlName="email" name="email" id="email"
                    [ngClass]="{alert: accountForm.controls['email'].touched && accountForm.controls['email'].invalid}" />
            </div>

            <div class="form">
                <label>Celular *</label><br />
                <input type="text" formControlName="cellphone" name="cellphone" id="cellphone" mask="(00) 00000-0000"
                    [ngClass]="{alert: accountForm.controls['cellphone'].touched && accountForm.controls['cellphone'].invalid}" />
            </div>

            <div class="form">
                <label>Registro Geral (RG) *</label><br />
                <input type="text" formControlName="rg" name="rg" id="rg"
                    [ngClass]="{alert: accountForm.controls['rg'].touched && accountForm.controls['rg'].invalid}" />
            </div>

            <div class="form">
                <label>Estado Civil *</label><br />
                <select formControlName="maritalStatus" name="maritalStatus" id="maritalStatus"
                    [ngClass]="{alert: accountForm.controls['maritalStatus'].touched && accountForm.controls['maritalStatus'].invalid}">
                    <option value="" disabled="disabled" selected="selected">Escolha a opção</option>
                    <option value="Single">Solteiro (a)</option>
                    <option value="Married">Casado (a)</option>
                    <option value="Divorced">Divorciado (a)</option>
                    <option value="Widower">Viúvo (a)</option>
                </select>
            </div>

            <div class="form">
                <label>Conta *</label><br />
                <mat-select formControlName="accounts" name="accounts" id="accounts" (selectionChange)="addCooperativeAccount($event.value)"
                    [ngClass]="{alert: accountForm.controls['accounts'].touched && accountForm.controls['accounts'].invalid}" multiple>
                    <mat-option *ngFor="let item of listAccounts;" [value]="item" style="color: #008DD0 !important;">{{item.resume}}</mat-option>
                </mat-select>
            </div>

            <div class="form">
                <label>Senha provisória *</label><br />
                <input type="password" formControlName="password" name="password" id="password"
                    [ngClass]="{alert: accountForm.controls['password'].touched && accountForm.controls['password'].invalid}" />
            </div>

            <div class="form">
                <label>Nome do Endereço</label><br />
                <input type="text" formControlName="addressName" name="addressName" id="addressName"
                    [ngClass]="{alert: accountForm.controls['addressName'].touched && accountForm.controls['addressName'].invalid}" />
            </div>

            <div class="form">
                <label>Tipo de Endereço *</label><br />
                <select formControlName="addressType" name="addressType" id="addressType"
                    [ngClass]="{alert: accountForm.controls['addressType'].touched && accountForm.controls['addressType'].invalid}">
                    <option value="" disabled="disabled" selected="selected">Escolha a opção</option>
                    <option value="Residential">Residencial</option>
                    <option value="Store">Comercial</option>
                    <option value="Other">Outro</option>
                </select>
            </div>

            <div class="form">
                <label>CEP *</label><br />
                <input type="text" formControlName="postalCode" name="postalCode" id="postalCode" (blur)="searchCep()"
                    mask="00000-000" [ngClass]="{alert: accountForm.controls['postalCode'].touched && accountForm.controls['postalCode'].invalid}" />
            </div>

            <div class="form">
                <label>Rua *</label><br />
                <input type="text" formControlName="logradouro" name="logradouro" id="logradouro"
                    [ngClass]="{alert: accountForm.controls['logradouro'].touched && accountForm.controls['logradouro'].invalid}" />
            </div>

            <div class="form">
                <label>Nº *</label><br />
                <input type="text" formControlName="number" name="number" id="number"
                    [ngClass]="{alert: accountForm.controls['number'].touched && accountForm.controls['number'].invalid}" />
            </div>

            <div class="form">
                <label>Complemento</label><br />
                <input type="text" formControlName="complement" name="complement" id="complement" />
            </div>

            <div class="form">
                <label>Bairro *</label><br />
                <input type="text" formControlName="neighborhood" name="neighborhood" id="neighborhood"
                    [ngClass]="{alert: accountForm.controls['neighborhood'].touched && accountForm.controls['neighborhood'].invalid}" />
            </div>

            <div class="form">
                <label>Cidade *</label><br />
                <input type="text" formControlName="city" name="city" id="city"
                    [ngClass]="{alert: accountForm.controls['city'].touched && accountForm.controls['city'].invalid}" />
            </div>

            <div class="form">
                <label>Estado *</label><br />
                <input type="text" formControlName="state" name="state" id="state"
                    [ngClass]="{alert: accountForm.controls['state'].touched && accountForm.controls['state'].invalid}" />
            </div>

            <div class="form right">
                <input type="submit" value="Salvar cadastro" />
            </div>

        </form>
    </div>

</div>