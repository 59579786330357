import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP } from '../../../app.config';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  login(username: string, password: string) {
    let body = `grant_type=password&username=${username}&password=${password}&client_id=${APP.CLIENT_ID}&client_secret=${APP.CLIENT_SECRET}`;

    return this.http.post(APP.URL_TOKEN, body, { headers: this.headers });
  }

  isAuthenticated() {
    return this.cookieService.check('token') ? true : false;
  }
}
