<div class="section">
    <div class="section__header">
        <div class="container-header">
            <div class="buscaHeader styleCampos">
                <input type="text" id="txtBuscaHeader" placeholder="Pesquisa por: Nome ou CPF" />
                <i class="fas fa-search" id="img-search" ></i>
            </div>

            <div class="filtroHeader styleCampos">
                <p class="filtroCooperados">Filtros</p>
                <i class="fas fa-filter"></i>
            </div>

            <div class="exportarDados styleCampos">
                <p class="expDados">Exportar dados</p>
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
    </div>

    <div class="listaCooperados" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown()" [scrollWindow]="false">
        <div class="line-header"></div>
        <div *ngFor="let item of requests; let i = index">

            <div class="containerListaCooperados">
                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Solicitante</h3>

                    <div class="container-dados">

                        <div class="listUsers__grid">
                            <i class="fas fa-user"></i>
                            <p class="users">{{item.cooperativeCustomer.name}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="fas fa-file-alt"></i>
                            <p class="users letters">{{item.cooperativeCustomer.registration}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="fas fa-phone-volume"></i>
                            <p class="users tel">{{item.cooperativeCustomer.cellphone}}</p>
                        </div>
                        
                    </div>

                </div>

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Número da Conta</h3>

                    <div class="container-dados">

                        <div class="listUsers__grid">
                            <i class="far fa-file-alt "></i>
                            <p class="users">{{item.account.number}}</p>
                        </div>

                    </div>

                </div>

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Solicitação</h3>

                    <div class="container-dados">

                        <div class="listUsers__grid">
                            <i class="far fa-flag"></i>
                            <p class="users">{{item.type}}</p>
                        </div>
                        <div class="listUsers__grid">
                            <i class="far fa-circle"></i>
                            <p class="users">{{item.status}}</p>
                        </div>
                        <div class="listUsers__grid">
                            <i class="far fa-list-alt"></i>
                            <p class="users">{{item.reason}}</p>
                        </div>

                    </div>
                </div>

                <div class="headerLista rigth">
                    <h3 class="title" *ngIf="i === 0">Ações</h3>

                    <div class="container-dados">
                        <div *ngIf="item.status != 'Aprovado' && item.status != 'Reprovado'" class="listUsers__grid">
                            <i class="fas fa-user-edit"></i>
                            <p class="users" (click)="updateRequest(item._id)">Responder</p>
                        </div>

                        <div *ngIf="item.status == 'Aprovado'" class="listUsers__grid">
                            <i class="fas fa-check-circle"></i>
                            <p class="users" (click)="updateRequest(item._id)">Ver</p>
                        </div>

                        <div *ngIf="item.status == 'Reprovado'" class="listUsers__grid">
                            <i class="fas fa-times-circle"></i>
                            <p class="users" (click)="updateRequest(item._id)">Ver</p>
                        </div>
                    </div>

                </div>

                <div class="line"></div>
            </div>

            <div class="line-header next"></div>
        </div>
    </div>
</div>