import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP } from '../../../app.config';
import { stringify } from 'query-string';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.cookieService.get('token'),
  });

  list(payload: any) {
    let queryString = stringify(payload);

    if (queryString) {
      queryString = '?' + queryString;
    }

    console.log('querystring => ', queryString);

    return this.http.get<any>(APP.URL_BASE + '/orders' + queryString, { headers: this.headers });
  }

}
