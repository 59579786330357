<div class="section">
    <div class="section__header">
        <div class="container-header" [formGroup]="searchForm">
            <div class="buscaHeader styleCampos" style="width: 384px;">
                <input type="text" id="txtBuscaHeader" formControlName="search" name="search" placeholder="Número do Pedido ou Matricula" />
                <i class="fas fa-search" id="img-search" ></i>
            </div>
            <div class="buscaHeader styleCampos" style="width: 166px;">
                <input type="date" id="dtBeginHeader" formControlName="dtBegin" name="dtBegin" (blur)="setEndDate()" />                
            </div>
            <div class="buscaHeader styleCampos" style="width: 166px;">
                <input type="date" id="dtEndHeader" formControlName="dtEnd" name="dtEnd" (blur)="setBeginDate()" />                
            </div>

            <div class="exportarDados styleCampos" (click)="find()">
                <p class="expDados">Pesquisar</p>
                <i class="fas fa-filter"></i>
            </div>

            <div class="exportarDados styleCampos" (click)="exportOrders()">
                <p class="expDados">Exportar dados</p>
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
    </div>

    <div class="listaCooperados" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown()" [scrollWindow]="false">
        <div class="line-header"></div>
        <div *ngFor="let item of orders; let i = index">

            <div class="containerListaCooperados">

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Pedido</h3>

                    <div class="container-dados">
                        <div class="listUsers__grid" *ngIf="item.number">
                            <i class="fas fa-flag"></i>
                            <p class="users">Número: {{item.number}}</p>
                        </div>

                        <div class="listUsers__grid" *ngIf="item.totalValue">
                            <i class="fas fa-wallet"></i>
                            <p class="users tel">{{item.totalValue | currency : 'R$'}}</p>
                        </div>
                    </div>

                </div>

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Estabelecimento</h3>

                    <div class="container-dados">
                        <div class="listUsers__grid">
                            <i class="fas fa-map-marker-alt"></i>
                            <p class="users">{{item.estabilishment}}</p>
                        </div>
                    </div>

                </div>

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Comprador</h3>

                    <div class="container-dados">

                        <div class="listUsers__grid">
                            <i class="fas fa-user"></i>
                            <p class="users">{{item.buyer.name}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="far fa-file-alt"></i>
                            <p class="users">{{item.buyer.registration}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="far fa-credit-card"></i>
                            <p class="users">{{item.buyer.account.cardNumber}}</p>
                        </div>
                    </div>
                </div>

                <div class="headerLista rigth">
                    <h3 class="title" *ngIf="i === 0">Conta</h3>

                    <div class="container-dados">
                        <div class="listUsers__grid">
                            <i class="far fa-address-book"></i>
                            <p class="users">{{item.buyer.account.account.number}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="far fa-bookmark"></i>
                            <p class="users" *ngIf="item.buyer.account.profile == 'Holder'">Titular</p>
                            <p class="users" *ngIf="item.buyer.account.profile == 'Dependent'">Dependente</p>
                        </div>
                    </div>

                </div>

                <div class="line"></div>
            </div>

            <div class="line-header next"></div>
        </div>
    </div>
</div>