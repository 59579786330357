import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CooperativesService } from '../../../services/cooperatives/cooperatives.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class AccountListComponent implements OnInit {
  accounts = [];
  cooperatives = [];
  limit = 10;
  skip = 0;
  accountNumber: '';
  searchForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cooperativesService: CooperativesService) { }

  ngOnInit(): void {
    this.accounts = [];
    this.cooperatives = [];
    this.searchForm = this.fb.group({
      search: new FormControl('')
    });

  }

  find() {
    this.skip = 0;
    this.limit = 10;
    this.cooperatives = [];

    let search = this.searchForm.value.search;
    console.warn('busca: ', search);

    if (!search) {
      this.listAccounts().then((data) => { this.accounts = data; });
      return;
    }

    this.listAccounts(search).then((data) => { 
      this.accounts = data; 
    });
  }

  updateLimit(cooperativeId, accountId): void {


    this.cooperativesService.editLimit(cooperativeId, accountId).subscribe((data) => {

      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Limite atualizado com sucesso.'
      }).then(() => window.location.href = "/contas");

    }, (error) => {

      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });

    });
  }

  removeAccount(cooperativeId, accountId) {
    this.cooperativesService.get(cooperativeId).subscribe(coop => {

      if (coop) {
        let total = 0;
        let accountCooperatives = [];

        coop['accounts'].map(x => {

          total += 1;
          let accountCooperative = {
            accountId: x.account._id,
            profile: x.profile,
            cardNumber: x.cardNumber,
            cardSerialNumber: x.cardSerialNumber,
            status: x.status
          }

          if (x._id == accountId) {
            accountCooperative['status'] = 'Inactive';
          }

          accountCooperatives.push(accountCooperative);

        });

        coop['accountCooperatives'] = accountCooperatives;
      }

      this.cooperativesService.edit(coop).subscribe((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Operação realizada com sucesso.'
        }).then(() => window.location.href = "/contas");
      }, (error) => {
        console.warn('ERROR >> ', error);
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          text: 'Ocorreu um erro inesperado!'
        });
      });

    });

  }

  listAccounts(search = null) {
    return new Promise<any[]>((resolve) => {

      this.cooperativesService.listCooperatives({ registration: search, isEnable: true, 'accounts.status': "Active" }).subscribe(data => {
        
          if (data.length > 0) {

            let coopPromises = data[0].accounts.map(async acc => {
              
              if(acc.status !== 'Inactive') {

                let cooperative = {
                  id: data[0]._id,
                  name: data[0].name,
                  account: {
                    id: acc._id,
                    number: acc.account.number,
                    profile: acc.profile,
                    cardNumber: acc.cardNumber,
                    status: acc.status,
                    creditCardLimit: acc.creditCardLimit
                  }
                }
    
                if (acc.profile === 'Holder') {
    
                  cooperative.account['holder'] = {
                    registration: data[0].registration,
                    name: data[0].name
                  }
    
                  this.cooperatives.push(cooperative);
    
                } else {  
    
                  await new Promise((resolve) => {
                    this.cooperativesService.listCooperatives({ 'accounts.account._id': acc.account._id, isEnable: true }).subscribe(holder => {
    
                      let accHolder = holder.find(x => x.accounts.find(y => y.profile === "Holder" && y.account._id.toString() === acc.account._id.toString()));
      
                      cooperative.account['holder'] = {
                        registration: accHolder.registration,
                        name: accHolder.name
                      }
        
                      this.cooperatives.push(cooperative);
                      resolve(this.cooperatives);
      
                    });
                  });               
    
                }

              }
  
            });
  
            Promise.all(coopPromises).then(() => {
              resolve(this.cooperatives);
            });
  
          } else {
  
            Swal.fire({
              icon: 'info',
              title: 'Info',
              text: 'Nenhum registro foi encontrado.'
            }).then(() => window.location.reload());
  
          }

      });

    });
  }
}
