import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EstablishmentsService } from '../../services/establishments/establishments.service';
import { EmployeesService } from '../../services/employees/employees.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { CooperativesService } from '../../services/cooperatives/cooperatives.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  cooperatives: number;
  employees: number;
  establishments: number;
  accounts: number;

  constructor(
    private cookieService: CookieService,
    private establishmentsService: EstablishmentsService,
    private employeesService: EmployeesService,
    private accountsService: AccountsService,
    private cooperativesService: CooperativesService
  ) { }

  ngOnInit(): void {

    // if (!this.cookieService.get('user')) {
    //   let username = this.cookieService.get('username');
      
    //   this.employeesService.listEmployees({ registration: username, isEnable: true }).subscribe((employees) => {
    //     this.cookieService.set('user', JSON.stringify(employees[0]), 1);
    //   });
    // }

    this.cooperatives = 0;
    this.employees = 0;
    this.establishments = 0;
    this.accounts = 0;
    
    this.establishmentsService.listEstablishments({ isEnable: true }).subscribe(data => { this.establishments = data.length});
    this.employeesService.listEmployees({ isEnable: true }).subscribe(data => this.employees = data.length);
    this.accountsService.listAccounts({ isEnable: true }).subscribe(data => this.accounts = data.length);
    this.cooperativesService.listCooperatives({ isEnable: true }).subscribe(data => this.cooperatives = data.length);
  }

}
