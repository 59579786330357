<div [ngClass]="classSideBar">
    <div class="sidebar">
        <div class="sidebar-menu">
            <div class="logo">
                <img src="../../../assets/images/Coopama-Logo-branco.svg" routerLink="home" alt="">
            </div>
            <div class="center">
                <span class="title-name">
                    <h2 class="name">Olá, {{user.name}}</h2>
                </span>
            </div>
            <div class="title-sidebar">
                <h3 class="title">Principal</h3>
            </div>
            <li class="item">
                <a routerLink="/" class="menu-btn" (click)="clickHome()">
                    <i class="fas fa-home"></i>
                    <span>Início</span>
                </a>
            </li>
            <li class="item" *ngFor="let item of menus">
                <div class="menu-btn" (click)="changeClassSubMenu(item)">
                    <i [ngClass]="item.classIcon"></i>
                    <span>{{item.name}}<i class="fa fa-chevron-down drop-down" aria-hidden="true"></i></span>
                </div>
                <div [ngClass]="calculateClassSubMenu(item)">
                    <a *ngFor="let sub of item.subs" routerLink="{{sub.url}}" (click)="clickSubMenu(sub.url)">
                        <i class="fas fa-layer-group"></i>
                        <span>{{sub.name}}</span>
                    </a>
                </div>
            </li>
            <div class="title-sidebar">
                <h3 class="title">Ajustes</h3>
            </div>
            <div class="menu-footer">
                <li class="item">
                    <a class="menu-btn footer" [routerLink]="['/perfil', user._id]" style="cursor: pointer;">
                        <i class="fas fa-user-alt"></i>
                        <span>Perfil</span>
                    </a>
                </li>
                <li class="item">
                    <a class="menu-btn footer1" (click)="logout()" style="cursor: pointer;">
                        <i class="fas fa-sign-out-alt"></i>
                        <span>Sair</span>
                    </a>
                </li>
                <div class="title-sidebar">
                    <h5 class="title">Sistema Administrativo Coopama</h5>
                </div>
            </div>
        </div>
    </div>

    <div class="header">
        <div class="grid-header">
            <div class="btn-sidebar" (click)="changeClassSideBar()">
                <i class="fas fa-bars"></i>
                <span class="btn-header">{{barName}}</span>
            </div>
            <!-- <div class="busca">
                <input type="text" id="txtBusca" placeholder="Pesquisar cooperado, contas e outros" />
                <i class="fas fa-search" id="img-search"></i>
            </div>

            <div class="notifications">
                <div class="btn-notificacao">
                    <i class="far fa-bell" id="icon-notificacao"></i>
                </div>
            </div> -->
        </div>
    </div>
    <div class="main-section">
        <router-outlet></router-outlet>        
    </div>
</div>

<!-- <div class="popup-notificacao">
    <div class="header-popup">
        <h3 class="header-title">Notificações</h3>
    </div>

    <div class="container-popup">
        <div class="grid-popup">
            <div class="img-popup">
                <img src="../../../assets/images/Foto.png" alt="">
            </div>

            <div class="text-popup">
                <p class="name-popup">
                    Francisco Alberto
                </p>

                <p class="solicitacao-popup">
                    Solicitou aumento de crédito
                </p>

                <p class="data-popup">
                    23 de Julho, 15:12PM
                </p>
            </div>

            <p class="icon-fechar">
                <i class="fas fa-times"></i>
            </p>

        </div>

        <div class="line-popup"></div>

    </div>

    <div class="container-popup">
        <div class="grid-popup">
            <div class="img-popup">
                <img src="../../../assets/images/Foto.png" alt="">
            </div>

            <div class="text-popup">
                <p class="name-popup">
                    Francisco Alberto
                </p>

                <p class="solicitacao-popup">
                    Solicitou aumento de crédito
                </p>

                <p class="data-popup">
                    23 de Julho, 15:12PM
                </p>
            </div>

            <p class="icon-fechar">
                <i class="fas fa-times"></i>
            </p>

        </div>

        <div class="line-popup"></div>

    </div>

    <div class="line-bottom"></div>

    <div class="container-popup">
        <div class="grid-popup">
            <div class="img-popup">
                <img src="../../../assets/images/Foto.png" alt="">
            </div>

            <div class="text-popup">
                <p class="name-popup">
                    Francisco Alberto
                </p>

                <p class="solicitacao-popup">
                    Solicitou aumento de crédito
                </p>

                <p class="data-popup">
                    23 de Julho, 15:12PM
                </p>
            </div>

            <p class="icon-fechar">
                <i class="fas fa-times"></i>
            </p>

        </div>

        <div class="line-popup"></div>

    </div>

    <div class="line-bottom"></div>

    <div class="container-popup">
        <div class="grid-popup">
            <div class="img-popup">
                <img src="../../../assets/images/Foto.png" alt="">
            </div>

            <div class="text-popup">
                <p class="name-popup">
                    Francisco Alberto
                </p>

                <p class="solicitacao-popup">
                    Solicitou aumento de crédito
                </p>

                <p class="data-popup">
                    23 de Julho, 15:12PM
                </p>
            </div>

            <p class="icon-fechar">
                <i class="fas fa-times"></i>
            </p>
        </div>
    </div>
</div> -->