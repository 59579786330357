import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cooperative-account',
  templateUrl: './cooperative-account.component.html',
  styleUrls: ['./cooperative-account.component.css']
})
export class CooperativeAccountComponent implements OnInit {
  cooperativeAccountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CooperativeAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    this.cooperativeAccountForm = this.fb.group({
      profile: new FormControl("", [Validators.required]),
      dependentType: new FormControl(""),
      creditCardLimit: new FormControl(""),
    });    

  }

  cancel(): void {
    this.dialogRef.close({ data: { cancel: true } });
  }

  save(): void {
    if (!this.cooperativeAccountForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.cooperativeAccountForm.markAllAsTouched();

      return;
    }
    
    let paylod = this.cooperativeAccountForm.value;


    this.dialogRef.close({ data: paylod });    
  }

  changedProfile(): void {
    if(this.cooperativeAccountForm.get('profile').value == 'Holder')
      this.cooperativeAccountForm.controls['dependentType'].disable({onlySelf: true});
  }
}
