import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostalcodeService {
  
  constructor(private http: HttpClient) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  getAddress(postalCode) {
    let url = `https://viacep.com.br/ws/${postalCode}/json/`;

    return this.http.get(url, { headers: this.headers });
  }
}
