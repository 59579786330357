import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AccountsService } from '../../../services/accounts/accounts.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class AccountNewComponent implements OnInit {
  number;
  accountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AccountNewComponent>,
    private accountsService: AccountsService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.accountForm = this.fb.group({
      creditLimit: new FormControl('', [Validators.required])
    });

    this.number = this.data;
  }

  save() {
    if (!this.accountForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.accountForm.markAllAsTouched();

      return;
    }

    let model = this.accountForm.value;

    let payload = {
      number: this.number,
      creditLimit: model.creditLimit
    }

    this.accountsService.save(payload).subscribe((data) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Conta cadastrada com sucesso.'
      }).then(() => window.location.href = "/contas");
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
