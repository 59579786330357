import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP } from '../../../app.config';
import { stringify } from 'query-string';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentsService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.cookieService.get('token'),
  });

  listEstablishments(payload) {
    let queryString = stringify(payload);

    if(queryString) {
      queryString = '?' + queryString;
    }

    return this.http.get<any>(APP.URL_BASE + '/establishments' + queryString, { headers: this.headers });
  }

  get(establishmentId: String) {

    return this.http.get(APP.URL_BASE + '/establishments/' + establishmentId, { headers: this.headers });
  }

  save(payload: any) {
    console.log('establishment save => ', payload);

    return this.http.post(APP.URL_BASE + '/establishments', payload, { headers: this.headers });
  }

  edit(payload: any) {
    console.log('establishment edit => ', payload);

    return this.http.put(APP.URL_BASE + '/establishments', payload, { headers: this.headers });
  }

  remove(establishmentId: any) {
    console.log('establishment remove => ', establishmentId);

    return this.http.delete(APP.URL_BASE + '/establishments/' + establishmentId, { headers: this.headers });
  }
}
