import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { EstablishmentsService } from '../../../services/establishments/establishments.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class EstablishmentListComponent implements OnInit {
  establishments = [];
  limit = 10;
  skip = 0;
  searchForm: FormGroup;

  constructor(private fb: FormBuilder, private establishmentsService: EstablishmentsService) { }

  ngOnInit(): void {
    this.establishments = [];
    this.searchForm = this.fb.group({
      search: new FormControl('')
    });

    this.establishmentsService.listEstablishments({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.establishments = data });
  }

  find() {
    let search = this.searchForm.value.search;
    
    if (!search) {
      this.establishmentsService.listEstablishments({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.establishments = data });
      return;
    }

    this.establishmentsService.listEstablishments({ responsible: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { 
      this.establishments = data;
      console.warn('busca: ', this.establishments);

      if(this.establishments.length === 0) {
        this.establishmentsService.listEstablishments({ cnpj: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(payload => { this.establishments = payload });
      }

    });
  }

  onScrollDown() {
    this.skip += this.limit;

    this.establishmentsService.listEstablishments({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { 
      this.establishments = this.establishments.concat(data) 
    });
  }

  removeEstablishment(establishmentId) {

    this.establishmentsService.remove(establishmentId).subscribe(data => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Estabelecimento excluído com sucesso.'
      }).then(() => window.location.reload());
    });

  }

}
