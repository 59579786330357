import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EmployeesService } from '../../services/employees/employees.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  user;
  classSideBar = '';
  barName = 'Início';
  menus = [
    {
      name: 'Cooperados',
      isSelected: false,
      classIcon: 'fas fa-user-alt',
      subs: [
        {
          name: 'Todos',
          url: 'cooperados',
        },
        {
          name: 'Pedidos',
          url: 'pedidos',
        },
        {
          name: 'Solicitações',
          url: 'solicitacoes',
        }
      ]
    },
    {
      name: 'Funcionários',
      isSelected: false,
      classIcon: 'fas fa-users',
      subs: [
        {
          name: 'Todos',
          url: 'funcionarios',
        },
        {
          name: 'Adicionar',
          url: 'funcionario-novo',
        }
      ]
    },
    {
      name: 'Estabelecimentos',
      isSelected: false,
      classIcon: 'fas fa-hotel',
      subs: [
        {
          name: 'Todos',
          url: 'estabelecimentos',
        },
        {
          name: 'Adicionar',
          url: 'estabelecimento-novo',
        }
      ]
    },
    {
      name: 'Contas',
      isSelected: false,
      classIcon: 'fas fa-cog',
      subs: [
        {
          name: 'Todas',
          url: 'contas',
        },
        {
          name: 'Dependentes',
          url: 'dependentes',
        }
      ]
    }
  ]

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private employeesService: EmployeesService
  ) { }

  ngOnInit(): void {
    let url = this.router.url.split('/');
    this.getBarName(url[1]);

    this.classSideBar = 'wrapper';

    if (this.cookieService.get('user'))
      this.user = JSON.parse(this.cookieService.get('user'));

    if (!this.cookieService.get('user')) {
      let username = this.cookieService.get('username');

      this.employeesService.listEmployees({ registration: username, isEnable: true }).subscribe((employees) => {
        this.cookieService.set('user', JSON.stringify(employees[0]), 1);
        this.user = employees[0];
      });
    }
  }

  changeClassSideBar() {
    if (this.classSideBar === 'wrapper')
      this.classSideBar = 'wrapper collapse'
    else if (this.classSideBar === 'wrapper collapse')
      this.classSideBar = 'wrapper';
  }

  changeClassSubMenu(item) {
    item.isSelected = !item.isSelected;
  }

  calculateClassSubMenu(item) {
    if (item.isSelected)
      return 'sub-menu show';

    if (!item.isSelected)
      return 'sub-menu';
  }

  clickSubMenu(item) {
    console.warn('url>> ', item);
    this.getBarName(item);
  }

  clickHome() {
    this.barName = 'Início';
  }

  getBarName(route) {

    switch (route) {
      case 'home':
        this.barName = 'Início';
        break;
      case 'cooperados':
        this.barName = 'Cooperados';
        break;
      case 'cooperado-editar':
        this.barName = 'Cooperados > Editar';
        break;
      case 'funcionarios':
        this.barName = 'Funcionários';
        break;
      case 'funcionario-novo':
        this.barName = 'Funcionários > Novo';
        break;
      case 'funcionario-editar':
        this.barName = 'Funcionários > Editar';
        break;
      case 'estabelecimentos':
        this.barName = 'Estabelecimentos';
        break;
      case 'estabelecimento-novo':
        this.barName = 'Estabelecimentos > Novo';
        break;
      case 'estabelecimento-editar':
        this.barName = 'Estabelecimentos > Editar';
        break;
      case 'contas':
        this.barName = 'Contas';
        break;
      case 'dependentes':
        this.barName = 'Dependentes';
        break;
      case 'solicitacoes':
        this.barName = 'Cooperados > Solicitações';
        break;
      case 'perfil':
        this.barName = 'Perfil';
        break;
      case 'pedidos':
        this.barName = 'Cooperados > Pedidos';
        break;
    }

  }

  logout() {
    this.cookieService.deleteAll();
    this.router.navigate(['/login']);
    window.location.reload();
  }

}
