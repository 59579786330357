import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { EstablishmentsService } from '../../services/establishments/establishments.service';
import { EmployeesService } from '../../services/employees/employees.service';
import { PostalcodeService } from '../../services/postalcode/postalcode.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  establishments;
  employeeForm: FormGroup;
  employeeId: String;

  constructor(
    private fb: FormBuilder,
    private establishmentsService: EstablishmentsService,
    private employeesService: EmployeesService,
    private postalcodeService: PostalcodeService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.employeeForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required]),
      registration: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl('', [Validators.required]),
      profile: new FormControl('', [Validators.required]),
      establishment: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      addressType: new FormControl('', [Validators.required]),
      addressName: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      logradouro: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(''),
      neighborhood: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required])
    });

    this.establishments = [];
    this.establishmentsService.listEstablishments({ isEnable: true }).subscribe(data => this.establishments = data);

    this.employeeId = this.route.snapshot.paramMap.get("id");
    this.employeesService.get(this.employeeId).subscribe((data) => { 
      console.log(data);
      this.employeeForm.controls['name'].setValue(data['name']);
      this.employeeForm.controls['cpf'].setValue(data['cpf']);
      this.employeeForm.controls['registration'].setValue(data['registration']);
      this.employeeForm.controls['email'].setValue(data['email']);
      this.employeeForm.controls['cellphone'].setValue(data['cellphone']);
      this.employeeForm.controls['profile'].setValue(data['profile']);
      this.employeeForm.controls['establishment'].setValue(data['establishment']['_id']);
      this.employeeForm.controls['addressType'].setValue(data['address']['type']);
      this.employeeForm.controls['addressName'].setValue(data['address']['name']);
      this.employeeForm.controls['postalCode'].setValue(data['address']['postalCode']);
      this.employeeForm.controls['logradouro'].setValue(data['address']['address']);
      this.employeeForm.controls['number'].setValue(data['address']['number']);
      this.employeeForm.controls['complement'].setValue(data['address']['complement']);
      this.employeeForm.controls['neighborhood'].setValue(data['address']['neighborhood']);
      this.employeeForm.controls['city'].setValue(data['address']['city']);
      this.employeeForm.controls['state'].setValue(data['address']['state']);

    });

  }

  edit() {
    if (!this.employeeForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.employeeForm.markAllAsTouched();

      return;
    }

    let model = this.employeeForm.value;

    let payload = {
      _id: this.employeeId,
      name: model.name,
      cpf: model.cpf,
      email: model.email,
      cellphone: model.cellphone,
      profile: model.profile,
      establishmentId: model.establishment,
      registration: model.registration,
      password: model.password,
      address: {
        name: model.addressName,
        type: model.addressType,
        address: model.logradouro,
        number: model.number,
        complement: model.complement,
        neighborhood: model.neighborhood,
        postalCode: model.postalCode,
        state: model.state,
        city: model.city
      }
    }

    this.employeesService.edit(payload).subscribe((data) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Funcionário atualizado com sucesso.'
      }).then(() => window.location.href = "/home");
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

  searchCep() {
    let postalCode = this.employeeForm.value.postalCode;

    if (postalCode.length != 8)
      return;

    this.postalcodeService.getAddress(postalCode).subscribe((data) => {

      this.employeeForm.controls['logradouro'].setValue(data['logradouro']);
      this.employeeForm.controls['neighborhood'].setValue(data['bairro']);
      this.employeeForm.controls['city'].setValue(data['localidade']);
      this.employeeForm.controls['state'].setValue(data['uf']);

    });
  }

}
