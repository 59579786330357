import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AccountsService } from '../../../services/accounts/accounts.service';
import { CooperativesService } from '../../../services/cooperatives/cooperatives.service';
import { PostalcodeService } from '../../../services/postalcode/postalcode.service';
import { CooperativeAccountComponent } from '../../cooperative/cooperative-account/cooperative-account.component';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class CooperativeNewComponent implements OnInit {

  cooperatives;
  listAccounts;
  cooperativeAccounts;
  accountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private accountService: AccountsService,
    private cooperativesService: CooperativesService,
    private postalcodeService: PostalcodeService
  ) { }



  ngOnInit(): void {
    this.cooperatives = [];
    this.listAccounts = [];
    this.cooperativeAccounts = [];

    this.accountForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required]),
      registration: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl('', [Validators.required]),
      rg: new FormControl('', [Validators.required]),
      maritalStatus: new FormControl('', [Validators.required]),
      accounts: new FormControl([], [Validators.required]),
      password: new FormControl('', [Validators.required]),
      addressType: new FormControl('', [Validators.required]),
      addressName: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      logradouro: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(''),
      neighborhood: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required])
    });

    this.accountService.listAccounts({ isEnable: true }).subscribe((data) => {
      this.listAccounts = data;

      this.cooperativesService.listCooperatives({ 'accounts.profile': 'Holder', isEnable: true }).subscribe((cooperatives) => {
        this.cooperatives = cooperatives;

        this.listAccounts.map((account) => {
          let cooperative = this.cooperatives.find(item => item.accounts.find(acc => acc.account.number == account.number && acc.profile == 'Holder'));

          if (cooperative) {

            account.resume = 'Conta: ' + account.number + ' | ' + cooperative.name;
            account.cooperative = {
              id: cooperative._id,
              name: cooperative.name
            };
          
          } 
          else {

            account.resume = 'Conta: ' + account.number;
          
          }
            
        });

      });

    });
  }

  save() {
    if (!this.accountForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.accountForm.markAllAsTouched();

      return;
    }
    debugger;
    let model = this.accountForm.value;

    let payload = {
      name: model.name,
      cpf: model.cpf,
      email: model.email,
      cellphone: model.cellphone,
      rg: model.rg,
      maritalStatus: model.maritalStatus,
      accountCooperatives: this.cooperativeAccounts,
      registration: model.registration,
      password: model.password,
      address: {
        name: model.addressName,
        type: model.addressType,
        address: model.logradouro,
        number: model.number,
        complement: model.complement,
        neighborhood: model.neighborhood,
        postalCode: model.postalCode,
        state: model.state,
        city: model.city
      }
    }

    this.cooperativesService.save(payload).subscribe((data) => {

      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Cooperado cadastrado com sucesso.'
      }).then(() => window.location.href = "/cooperados");
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

  searchCep() {
    let postalCode = this.accountForm.value.postalCode;

    if (postalCode.length != 8)
      return;

    this.postalcodeService.getAddress(postalCode).subscribe((data) => {

      this.accountForm.controls['logradouro'].setValue(data['logradouro']);
      this.accountForm.controls['neighborhood'].setValue(data['bairro']);
      this.accountForm.controls['city'].setValue(data['localidade']);
      this.accountForm.controls['state'].setValue(data['uf']);

    });
  }

  addCooperativeAccount(accounts): void {
    let options = this.cooperativeAccounts;
    this.cooperativeAccounts = [];

    for (let i = 0; i < accounts.length; i++) {

      let isSelected = options.find(x => x._id === accounts[i]._id);

      if (isSelected) {
        this.cooperativeAccounts.push(accounts[i]);
      }
      else {
        const dialogRef = this.dialog.open(CooperativeAccountComponent, { width: "100%", maxWidth: "500px", minWidth: "150px", data: accounts[i] });

        dialogRef.afterClosed().subscribe(result => {
          if (result.data.cancel) {
            //accounts.splice(i, 1);
            return;
          }

          result.data._id = accounts[i]._id;
          
          accounts[i].accountId = accounts[i]._id;
          accounts[i].profile = result.data.profile;
          accounts[i].dependentType = result.data.dependentType;
          accounts[i].creditCardLimit = result.data.creditCardLimit;

          this.cooperativeAccounts.push(accounts[i]);
          console.warn('cooperativeAccounts > ', this.cooperativeAccounts);
        });
      }

    };
  }

}
