import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarComponent } from '../../../components/sidebar/sidebar.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { CooperativeAccountComponent } from '../../cooperative/cooperative-account/cooperative-account.component';
import { AccountsService } from '../../../services/accounts/accounts.service';
import { CooperativesService } from '../../../services/cooperatives/cooperatives.service';
import { PostalcodeService } from '../../../services/postalcode/postalcode.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class CooperativeEditComponent implements OnInit {

  cooperatives;
  listAccounts;
  cooperativeAccounts;
  accountForm: FormGroup;
  cooperativeSelected;
  cooperativeId: String;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private accountService: AccountsService,
    private cooperativesService: CooperativesService,
    private postalcodeService: PostalcodeService,
    private route: ActivatedRoute,
    private router: Router,
    private sidebarComponent: SidebarComponent,
  ) { }

  ngOnInit(): void {
    let url = this.router.url.split('/');
    this.sidebarComponent.getBarName(url[1]);

    this.accountForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required]),
      registration: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl('', [Validators.required]),
      rg: new FormControl('', [Validators.required]),
      maritalStatus: new FormControl('', [Validators.required]),
      //accounts: new FormControl([], [Validators.required]),
      password: new FormControl(''),
      addressType: new FormControl('', [Validators.required]),
      addressName: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      logradouro: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(''),
      neighborhood: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required])
    });

    this.cooperatives = [];
    this.listAccounts = [];


    this.cooperativeId = this.route.snapshot.paramMap.get("id");
    this.cooperativesService.get(this.cooperativeId).subscribe((data) => {
      this.cooperativeSelected = data;

      this.accountForm.controls['name'].setValue(data['name']);
      this.accountForm.controls['cpf'].setValue(data['cpf']);
      this.accountForm.controls['registration'].setValue(data['registration']);
      this.accountForm.controls['email'].setValue(data['email']);
      this.accountForm.controls['cellphone'].setValue(data['cellphone']);
      this.accountForm.controls['rg'].setValue(data['rg']);
      this.accountForm.controls['maritalStatus'].setValue(data['maritalStatus']);
      this.accountForm.controls['addressType'].setValue(data['address']['type']);
      this.accountForm.controls['addressName'].setValue(data['address']['name']);
      this.accountForm.controls['postalCode'].setValue(data['address']['postalCode']);
      this.accountForm.controls['logradouro'].setValue(data['address']['address']);
      this.accountForm.controls['number'].setValue(data['address']['number']);
      this.accountForm.controls['complement'].setValue(data['address']['complement']);
      this.accountForm.controls['neighborhood'].setValue(data['address']['neighborhood']);
      this.accountForm.controls['city'].setValue(data['address']['city']);
      this.accountForm.controls['state'].setValue(data['address']['state']);


      // this.accountService.listAccounts({ isEnable: true }).subscribe((dataAcc) => {
      //   this.listAccounts = dataAcc;

      //   this.cooperativesService.listCooperatives({ 'accounts.profile': 'Holder', isEnable: true }).subscribe((cooperatives) => {
      //     this.cooperatives = cooperatives;

      //     let accountPromisses = this.listAccounts.map(async (account) => {
      //       let cooperative = this.cooperatives.find(item => item.accounts.find(acc => acc.account.number == account.number && acc.profile == 'Holder'));

      //       if (cooperative) {
      //         account.resume = 'Conta: ' + account.number + ' | ' + cooperative.name;
      //         account.cooperative = {
      //           id: cooperative._id,
      //           name: cooperative.name
      //         };
      //       }
      //       else {
      //         account.resume = 'Conta: ' + account.number;
      //       }
      //     });

      //     Promise.all(accountPromisses).then(() => {

      //       this.cooperativeAccounts = [];
      //       const promises = this.listAccounts.map(async item => {
      //         let json = data['accounts'].find(x => x.account._id == item._id && (x.status == 'Active' || x.status == 'NotHavingCard'));

      //         if (json) {
      //           item.profile = json.profile;
      //           item.dependentType = json.dependentType;
      //           item.creditLimit = json.creditLimit;

      //           this.cooperativeAccounts.push(item);
      //         }

      //         return item;
      //       });

      //       Promise.all(promises).then(() => this.accountForm.get('accounts').setValue(this.cooperativeAccounts));

      //     });

      //   });

      // });
    });

  }

  edit() {
    if (!this.accountForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.accountForm.markAllAsTouched();

      return;
    }

    let model = this.accountForm.value;
    
    console.warn(this.cooperativeSelected.accounts);
    let payload = {
      _id: this.cooperativeId,
      name: model.name,
      cpf: model.cpf,
      email: model.email,
      cellphone: model.cellphone,
      rg: model.rg,
      maritalStatus: model.maritalStatus,
      accountCooperatives: [],
      registration: model.registration,
      password: model.password,
      address: {
        name: model.addressName,
        type: model.addressType,
        address: model.logradouro,
        number: model.number,
        complement: model.complement,
        neighborhood: model.neighborhood,
        postalCode: model.postalCode,
        state: model.state,
        city: model.city
      }
    }

    this.cooperativeSelected.accounts.map(item => {

      let acc = {
        accountId: item.account._id,
		    profile: item.profile,
        status: item.status,
        cardNumber: item.cardNumber,
        cardSerialNumber: item.cardSerialNumber
      }

      payload.accountCooperatives.push(acc);

    });

    console.warn('payload', payload);
    this.cooperativesService.edit(payload).subscribe((data) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Cooperado atualizado com sucesso.'
      }).then(() => window.location.href = "/cooperados");
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

  searchCep() {
    let postalCode = this.accountForm.value.postalCode;

    if (postalCode.length != 8)
      return;

    this.postalcodeService.getAddress(postalCode).subscribe((data) => {

      this.accountForm.controls['logradouro'].setValue(data['logradouro']);
      this.accountForm.controls['neighborhood'].setValue(data['bairro']);
      this.accountForm.controls['city'].setValue(data['localidade']);
      this.accountForm.controls['state'].setValue(data['uf']);

    });
  }

  addCooperativeAccount(accounts): void {
    let options = this.cooperativeAccounts;
    this.cooperativeAccounts = [];

    for (let i = 0; i < accounts.length; i++) {

      let isSelected = options.find(x => x._id === accounts[i]._id);

      if (isSelected) {
        accounts[i].accountId = accounts[i]._id;
        this.cooperativeAccounts.push(accounts[i]);
      }
      else {
        const dialogRef = this.dialog.open(CooperativeAccountComponent, { width: "100%", maxWidth: "500px", minWidth: "150px", data: accounts[i] });

        dialogRef.afterClosed().subscribe(result => {
          if (result.data.cancel) {
            //accounts.splice(i, 1);
            return;
          }

          result.data._id = accounts[i]._id;

          accounts[i].accountId = accounts[i]._id;
          accounts[i].profile = result.data.profile;
          accounts[i].dependentType = result.data.dependentType;
          accounts[i].creditCardLimit = result.data.creditCardLimit;

          this.cooperativeAccounts.push(accounts[i]);
          console.warn('cooperativeAccounts > ', this.cooperativeAccounts);
        });
      }

    };
  }

}
