import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CooperativesService } from '../../../services/cooperatives/cooperatives.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class CooperativeListComponent implements OnInit {
  cooperatives = [];
  limit = 10;
  skip = 0;
  searchForm: FormGroup;

  constructor(private fb: FormBuilder, private cooperativesService: CooperativesService) { }

  ngOnInit(): void {
    this.cooperatives = [];
    this.searchForm = this.fb.group({
      search: new FormControl('')
    });

    this.cooperativesService.listCooperatives({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.cooperatives = data });
  }

  find() {
    let search = this.searchForm.value.search;
    console.warn('busca: ', search);

    if (!search) {
      this.cooperativesService.listCooperatives({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.cooperatives = data });
      return;
    }

    this.cooperativesService.listCooperatives({ name: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => {
      this.cooperatives = data;

      if (this.cooperatives.length === 0) {
        this.cooperativesService.listCooperatives({ registration: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(payload => { 
          this.cooperatives = payload;
          
          if (this.cooperatives.length === 0) {

            console.warn("chegou aqui!")
            this.cooperativesService.listCooperatives({ "accounts.cardNumber": search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(json => { this.cooperatives = this.cooperatives.concat(json); });
            
          }

        });
      }

    });
  }

  onScrollDown() {
    this.skip += this.limit;
    let search = this.searchForm.value.search;
    console.warn('busca: ', search);

    if (!search) {

      this.cooperativesService.listCooperatives({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => {
        this.cooperatives = this.cooperatives.concat(data);
      });

    } else {

      this.cooperativesService.listCooperatives({ name: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => {
        this.cooperatives = this.cooperatives.concat(data);

        if (this.cooperatives.length === 0) {
          
          this.cooperativesService.listCooperatives({ registration: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(payload => { 
            this.cooperatives = this.cooperatives.concat(payload); 
            
            if (this.cooperatives.length === 0) {
              console.warn("chegou aqui!")
              this.cooperativesService.listCooperatives({ "accounts.cardNumber": search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(json => { this.cooperatives = this.cooperatives.concat(json); });
            }
          
          });          
        }

      });

    }
  }

  removeCooperative(cooperativeId) {
    console.warn('remover cooperado ', cooperativeId);

    this.cooperativesService.remove(cooperativeId).subscribe(data => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Cooperado excluído com sucesso.'
      }).then(() => window.location.reload());
    })
  }

}
