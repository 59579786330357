import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../services/authentication/authentication.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {


  }

  login(model) {

    console.warn(model);
    this.authService.login(model.username, model.password)
      .subscribe((data: any) => {
        console.warn(data);

        this.cookieService.set('token', data.access_token, 1);
        this.cookieService.set('username', model.username, 1);
        this.router.navigate(['']);       

      }, (error) => {
        console.warn('ERROR >> ', error);
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          text: 'Ocorreu um erro inesperado!'
        });
      });

  }
}
