import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CooperativesService } from '../../../services/cooperatives/cooperatives.service';

@Component({
  selector: 'app-list-holder',
  templateUrl: './list-holder.component.html',
  styleUrls: ['./list-holder.component.css']
})
export class ListHolderComponent implements OnInit {

  accounts = [];
  cooperatives = [];
  limit = 10;
  skip = 0;
  accountNumber: '';
  searchForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cooperativesService: CooperativesService) { }

  ngOnInit(): void {
    this.accounts = [];
    this.cooperatives = [];
    this.searchForm = this.fb.group({
      search: new FormControl('')
    });

  }

  find() {
    this.skip = 0;
    this.limit = 10;

    let search = this.searchForm.value.search;
    console.warn('busca: ', search);

    if (!search) {
      this.listAccounts().then((data) => { this.accounts = data; });
      return;
    }

    this.listAccounts(search).then((data) => { this.accounts = data; });
  }

  updateLimit(cooperativeId, accountId): void {


    this.cooperativesService.editLimit(cooperativeId, accountId).subscribe((data) => {

      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Limite atualizado com sucesso.'
      }).then(() => window.location.href = "/dependentes");

    }, (error) => {

      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });

    });
  }

  removeAccount(cooperativeId, accountId) {
    this.cooperativesService.get(cooperativeId).subscribe(coop => {

      if (coop) {
        let total = 0;
        let accountCooperatives = [];

        coop['accounts'].map(x => {

          total += 1;
          let accountCooperative = {
            accountId: x.account._id,
            profile: x.profile,
            cardNumber: x.cardNumber,
            cardSerialNumber: x.cardSerialNumber,
            status: x.status
          }

          if (x._id == accountId) {
            accountCooperative['status'] = 'Inactive';
          }

          accountCooperatives.push(accountCooperative);

        });

        coop['accountCooperatives'] = accountCooperatives;
      }

      this.cooperativesService.edit(coop).subscribe((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Operação realizada com sucesso.'
        }).then(() => window.location.href = "/dependentes");
      }, (error) => {
        console.warn('ERROR >> ', error);
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          text: 'Ocorreu um erro inesperado!'
        });
      });

    });

  }

  listAccounts(search = null) {
    return new Promise<any[]>((resolve) => {
      let profile = 'Holder';

      this.cooperativesService.listCooperatives({ registration: search, isEnable: true, 'accounts.profile': profile, 'accounts.status': "Active" }).subscribe(data => {

        if (data.length > 0) {

          let account = data[0].accounts.find(x => x.profile === profile);
          this.accountNumber = account.account.number;

          this.cooperativesService.listCooperatives({ 'accounts.account._id': account.account._id, 'accounts.status': "Active", isEnable: true }).subscribe(payload => {
            console.log('payload : ', payload);
            this.cooperatives = payload;

            resolve(this.cooperatives);
          });

        } else {

          Swal.fire({
            icon: 'info',
            title: 'Info',
            text: 'Nenhum registro foi encontrado.'
          }).then(() => window.location.reload());

        }

      });

    });
  }

}
