import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { OrdersService } from '../../../services/orders/orders.service';
import { ExcelService } from '../../../components/excel/excel.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class OrderListComponent implements OnInit {
  orders = [];
  limit = 10;
  skip = 0;
  searchForm: FormGroup;

  constructor(private fb: FormBuilder, private ordersService: OrdersService, private excelService: ExcelService) { }

  ngOnInit(): void {
    this.orders = [];
    this.searchForm = this.fb.group({
      search: new FormControl(''),
      dtBegin: new FormControl(''),
      dtEnd: new FormControl('')
    });

    this.ordersService.list({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.orders = data });
  }

  find(clearList = true) {

    if (clearList) {
      this.orders = [];
      this.limit = 10;
      this.skip = 0;
    }

    let search = this.searchForm.value.search;
    let dtBegin = this.searchForm.value.dtBegin;
    let dtEnd = this.searchForm.value.dtEnd;
    let query = { skip: this.skip, limit: this.limit };

    if (search) {
      query['registration'] = search;
    }

    if (dtBegin && dtEnd) {

      let beginDate = new Date(dtBegin);
      let endDate = new Date(dtEnd);

      if (beginDate > endDate) {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Formulário inválido! A data inicial não pode ser maior que a data final.'
        });

        return;
      }

      query['dtBegin'] = dtBegin;
      query['dtEnd'] = dtEnd;
    }

    console.warn(query)

    this.ordersService.list(query).subscribe(data => {
      this.orders = this.orders.concat(data);

      if (this.orders.length === 0) {
        delete query['registration'];

        query['number'] = search;
        this.ordersService.list(query).subscribe(json => {
          this.orders = this.orders.concat(json);
        });

      }

    });
  }

  onScrollDown() {
    let total = this.skip + this.limit;

    if (this.orders.length >= total) {
      this.skip += this.limit;

      this.find(false);
    }

  }

  setEndDate() {
    let todayDate = new Date().toLocaleDateString();
    let splitDate = todayDate.split('/');

    this.searchForm.get('dtEnd').setValue(splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]);
  }

  setBeginDate() {
    let beginDate = this.searchForm.value.dtBegin;
    let endDate = this.searchForm.value.dtEnd;

    if (!beginDate && endDate) {

      this.searchForm.get('dtBegin').setValue(endDate);
    }

  }

  exportOrders() {

    let search = this.searchForm.value.search;
    let dtBegin = this.searchForm.value.dtBegin;
    let dtEnd = this.searchForm.value.dtEnd;
    let query = {};

    if (search) {
      query['registration'] = search;
    }

    if (dtBegin && dtEnd) {

      let beginDate = new Date(dtBegin);
      let endDate = new Date(dtEnd);

      if (beginDate > endDate) {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Formulário inválido! A data inicial não pode ser maior que a data final.'
        });

        return;
      }

      query['dtBegin'] = dtBegin;
      query['dtEnd'] = dtEnd;
    }

    console.warn(query)
    let orders = [];

    this.ordersService.list(query).subscribe(data => {

      if (data.length === 0) {

        delete query['registration'];

        query['number'] = search;
        this.ordersService.list(query).subscribe(json => {


          json.map(x => {

            orders.push({
              id: x._id,
              comprador: x.buyer.name,
              matricula: x.buyer.registration,
              conta: x.buyer.account.account.number,
              cartao: x.buyer.account.cardNumber,
              perfil: x.buyer.account.profile == 'Holder' ? 'Titutar' : 'Dependente',
              valortotal: x.totalValue,
              number: x.number,
              estabelecimento: x.estabilishment,
              data: x.createDate
            })

          });

          this.excelService.exportAsExcelFile(orders, 'lista-pedidos');
        });

      } else {

        data.map(x => {

          orders.push({
            id: x._id,
            comprador: x.buyer.name,
            matricula: x.buyer.registration,
            conta: x.buyer.account.account.number,
            cartao: x.buyer.account.cardNumber,
            perfil: x.buyer.account.profile == 'Holder' ? 'Titutar' : 'Dependente',
            valortotal: x.totalValue,
            number: x.number,
            estabelecimento: x.estabilishment,
            data: x.createDate
          })

        });

        this.excelService.exportAsExcelFile(orders, 'lista-pedidos');

      }

    });



  }

}