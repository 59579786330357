import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { EstablishmentsService } from '../../../services/establishments/establishments.service';
import { EmployeesService } from '../../../services/employees/employees.service';
import { PostalcodeService } from '../../../services/postalcode/postalcode.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class EmployeeNewComponent implements OnInit {
  establishments;
  employeeForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private establishmentsService: EstablishmentsService,
    private employeesService: EmployeesService,
    private postalcodeService: PostalcodeService
  ) { }

  ngOnInit(): void {
    this.establishments = [];
    this.employeeForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required]),
      registration: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl('', [Validators.required]),
      profile: new FormControl('', [Validators.required]),
      establishment: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      addressType: new FormControl('', [Validators.required]),
      addressName: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      logradouro: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(''),
      neighborhood: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required])
    })

    this.establishmentsService.listEstablishments({}).subscribe(data => this.establishments = data);
  }

  save() {
    if (!this.employeeForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.employeeForm.markAllAsTouched();

      return;
    }

    let model = this.employeeForm.value;

    let payload = {
      name: model.name,
      cpf: model.cpf,
      email: model.email,
      cellphone: model.cellphone,
      profile: model.profile,
      establishmentId: model.establishment,
      registration: model.registration,
      password: model.password,
      address: {
        name: model.addressName,
        type: model.addressType,
        address: model.logradouro,
        number: model.number,
        complement: model.complement,
        neighborhood: model.neighborhood,
        postalCode: model.postalCode,
        state: model.state,
        city: model.city
      }
    }

    this.employeesService.save(payload).subscribe((data) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Funcionário cadastrado com sucesso.'
      }).then(() => window.location.href = "/funcionarios");
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

  searchCep() {
    let postalCode = this.employeeForm.value.postalCode;

    if (postalCode.length != 8)
      return;

    this.postalcodeService.getAddress(postalCode).subscribe((data) => {

      this.employeeForm.controls['logradouro'].setValue(data['logradouro']);
      this.employeeForm.controls['neighborhood'].setValue(data['bairro']);
      this.employeeForm.controls['city'].setValue(data['localidade']);
      this.employeeForm.controls['state'].setValue(data['uf']);

    });
  }

}
