<div class="section">

    <div class="section__form">
        <form class="first-colum" [formGroup]="establishmentForm" (ngSubmit)="save()">
            <div class="form">
                <label>Código *</label><br />
                <input type="text" formControlName="code" name="code" id="code"
                    [ngClass]="{alert: establishmentForm.controls['code'].touched && establishmentForm.controls['code'].invalid}" />
            </div>

            <div class="form">
                <label>Nome *</label><br />
                <input type="text" formControlName="name" name="name" id="name"
                    [ngClass]="{alert: establishmentForm.controls['name'].touched && establishmentForm.controls['name'].invalid}" />
            </div>

            <div class="form">
                <label>CNPJ *</label><br />
                <input type="text" formControlName="cnpj" name="cnpj" id="cnpj" mask="00.000.000/0000-00"
                    [ngClass]="{alert: establishmentForm.controls['cnpj'].touched && establishmentForm.controls['cnpj'].invalid}" />
            </div>

            <div class="form">
                <label>Responsável *</label><br />
                <input type="text" formControlName="responsible" name="responsible" id="responsible"
                    [ngClass]="{alert: establishmentForm.controls['responsible'].touched && establishmentForm.controls['responsible'].invalid}" />
            </div>

            <div class="form">
                <label>E-mail *</label><br />
                <input type="text" formControlName="email" name="email" id="email"
                    [ngClass]="{alert: establishmentForm.controls['email'].touched && establishmentForm.controls['email'].invalid}" />
            </div>

            <div class="form">
                <label>Celular *</label><br />
                <input type="text" formControlName="cellphone" name="cellphone" id="cellphone" mask="(00) 00000-0000"
                    [ngClass]="{alert: establishmentForm.controls['cellphone'].touched && establishmentForm.controls['cellphone'].invalid}" />
            </div>

            <div class="form">
                <label>Nome do Endereço *</label><br />
                <input type="text" formControlName="addressName" name="addressName" id="addressName"
                    [ngClass]="{alert: establishmentForm.controls['addressName'].touched && establishmentForm.controls['addressName'].invalid}" />
            </div>

            <div class="form">
                <label>Tipo de Endereço *</label><br />
                <select formControlName="addressType" name="addressType" id="addressType"
                    [ngClass]="{alert: establishmentForm.controls['addressType'].touched && establishmentForm.controls['addressType'].invalid}">
                    <option value="" disabled="disabled" selected="selected">Escolha a opção</option>
                    <option value="Residential">Residencial</option>
                    <option value="Store">Comercial</option>
                    <option value="Other">Outro</option>
                </select>
            </div>

            <div class="form">
                <label>CEP *</label><br />
                <input type="text" formControlName="postalCode" name="postalCode" id="postalCode" (blur)="searchCep()"
                    mask="00000-000" [ngClass]="{alert: establishmentForm.controls['postalCode'].touched && establishmentForm.controls['postalCode'].invalid}" />
            </div>

            <div class="form">
                <label>Rua *</label><br />
                <input type="text" formControlName="logradouro" name="logradouro" id="logradouro"
                    [ngClass]="{alert: establishmentForm.controls['logradouro'].touched && establishmentForm.controls['logradouro'].invalid}" />
            </div>

            <div class="form">
                <label>Nº *</label><br />
                <input type="text" formControlName="number" name="number" id="number"
                    [ngClass]="{alert: establishmentForm.controls['number'].touched && establishmentForm.controls['number'].invalid}" />
            </div>

            <div class="form">
                <label>Complemento</label><br />
                <input type="text" formControlName="complement" name="complement" id="complement" />
            </div>

            <div class="form">
                <label>Bairro *</label><br />
                <input type="text" formControlName="neighborhood" name="neighborhood" id="neighborhood"
                    [ngClass]="{alert: establishmentForm.controls['neighborhood'].touched && establishmentForm.controls['neighborhood'].invalid}" />
            </div>

            <div class="form">
                <label>Cidade *</label><br />
                <input type="text" formControlName="city" name="city" id="city"
                    [ngClass]="{alert: establishmentForm.controls['city'].touched && establishmentForm.controls['city'].invalid}" />
            </div>

            <div class="form">
                <label>Estado *</label><br />
                <input type="text" formControlName="state" name="state" id="state"
                    [ngClass]="{alert: establishmentForm.controls['state'].touched && establishmentForm.controls['state'].invalid}" />
            </div>

            <div class="form right">
                <input type="submit" value="Salvar cadastro" />
            </div>

        </form>
    </div>

</div>