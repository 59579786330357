<div class="bg_form">
    <div class="bg_form-container">
        <img src="../../../assets/images/logo.svg" alt="">
        <h3>Área Administrativa</h3>

        <form class="login-form" #registerForm="ngForm" (ngSubmit)="login(registerForm.value)">

            <div class="textb">
                <input type="text" ngModel name="username" required>
                <div class="placeholder">Insira sua matrícula</div>
            </div>

            <div class="textb">
                <input type="password" ngModel name="password" required>
                <div class="placeholder" >Senha</div>
                <!-- <div class="show-password fas fa-eye-slash"></div> -->
            </div>

            <div class="btn-form-login">
                <input type="submit" value="Fazer o login">
            </div>

            <div class="checkbox">
                <!-- <input type="checkbox">
                <div class="fas fa-check"></div>
                Lembrar-me -->
                <span class="lembrar-senha">
                    <a routerLink="/login/recuperarsenha" id="lembrar-senha">Recuperar a senha</a>
                </span>
            </div>
        </form>
    </div>
</div>