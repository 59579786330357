import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})

export class ResetPasswordService {

  constructor(private http: HttpClient) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  resetPassword(username: string) {
    let body = {
      username: username
    }

    return this.http.post(APP.URL_BASE + '/resetpassword', body, { headers: this.headers });
  }
}
