<div class="section">
    <div class="cardsResumo">
        <div class="cards">
            <div class="center-text">
                <h3 class="title-card">Cooperados</h3>
                <p class="number-card">{{cooperatives}}</p>
            </div>
            <div class="border-cards"></div>
        </div>
        <div class="cards">
            <div class="center-text">
                <h3 class="title-card">Funcionários</h3>
                <p class="number-card">{{employees}}</p>
            </div>
            <div class="border-cards"></div>
        </div>
        <div class="cards">
            <div class="center-text">
                <h3 class="title-card">Estabelecimentos</h3>
                <p class="number-card">{{establishments}}</p>
            </div>
            <div class="border-cards"></div>
        </div>
        <div class="cards">
            <div class="center-text">
                <h3 class="title-card">Contas</h3>
                <p class="number-card">{{accounts}}</p>
            </div>
            <div class="border-cards"></div>
        </div>
    </div>
</div>