import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FullComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
    if (this.router.url === '/') {
      this.router.navigate(['/']);
    }
  }

}
