import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/auth/auth.guard';
import { EmptyComponent } from '../app/components/layouts/empty/empty.component';
import { FullComponent } from '../app/components/layouts/full/full.component';
import { HomeComponent } from './views/home/home.component';
import { CooperativeListComponent } from './views/cooperative/list/list.component';
import { CooperativeNewComponent } from './views/cooperative/new/new.component';
import { CooperativeEditComponent } from './views/cooperative/edit/edit.component';
import { EmployeeListComponent } from './views/employee/list/list.component';
import { EmployeeNewComponent } from './views/employee/new/new.component';
import { EmployeeEditComponent } from './views/employee/edit/edit.component';
import { EstablishmentListComponent } from './views/establishment/list/list.component';
import { EstablishmentNewComponent } from './views/establishment/new/new.component';
import { EstablishmentEditComponent } from './views/establishment/edit/edit.component';
import { AccountListComponent } from './views/account/list/list.component';
import { ProfileComponent } from './views/profile/profile.component';
import { RequestListComponent } from './views/request/list/list.component';
import { ForgotpasswordComponent } from './views/forgotpassword/forgotpassword.component';
import { LoginComponent } from './views/login/login.component';
import { OrderListComponent } from './views/order/list/list.component';
import { ListHolderComponent } from './views/account/list-holder/list-holder.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'cooperados', component: CooperativeListComponent, canActivate: [AuthGuard] },
      //{ path: 'cooperado-novo', component: CooperativeNewComponent, canActivate: [AuthGuard] },
      { path: 'cooperado-editar/:id', component: CooperativeEditComponent, canActivate: [AuthGuard] },
      { path: 'funcionarios', component: EmployeeListComponent, canActivate: [AuthGuard] },
      { path: 'funcionario-novo', component: EmployeeNewComponent, canActivate: [AuthGuard] },
      { path: 'funcionario-editar/:id', component: EmployeeEditComponent, canActivate: [AuthGuard] },
      { path: 'estabelecimentos', component: EstablishmentListComponent, canActivate: [AuthGuard] },
      { path: 'estabelecimento-novo', component: EstablishmentNewComponent, canActivate: [AuthGuard] },
      { path: 'estabelecimento-editar/:id', component: EstablishmentEditComponent, canActivate: [AuthGuard] },
      { path: 'contas', component: AccountListComponent, canActivate: [AuthGuard] },
      { path: 'dependentes', component: ListHolderComponent, canActivate: [AuthGuard] },
      { path: 'solicitacoes', component: RequestListComponent, canActivate: [AuthGuard] },
      { path: 'perfil/:id', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'pedidos', component: OrderListComponent, canActivate: [AuthGuard] }
    ]
  },
  {
    path: 'login',
    component: EmptyComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'recuperarsenha', component: ForgotpasswordComponent }
    ]
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
