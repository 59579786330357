<div class="section">
    <div class="section__header">
        <div class="container-header" [formGroup]="searchForm">
            <div class="buscaHeader styleCampos">
                <input type="text" id="txtBuscaHeader" formControlName="search" name="search" placeholder="Pesquise por: Matricula do titular da conta" (keyup.enter)="find()" />
                <i class="fas fa-search" id="img-search" (click)="find()"></i>
            </div>
        </div>
    </div>

    <div class="listaCooperados">
        <div class="line-header"></div>
        <div *ngFor="let item of accounts; let i = index">
            
            <div *ngFor="let acc of item.accounts; let j = index">

                <div class="containerListaCooperados" *ngIf="acc.account.number == accountNumber">
                    <div class="headerLista">
                        <h3 class="title" *ngIf="i === 0">Cooperado</h3>
    
                        <div class="container-dados">
    
                            <div class="listUsers__grid">
                                <i class="fas fa-user"></i>
                                <p class="users">{{item.name}}</p>
                            </div>
                            <div class="listUsers__grid">
                                <i class="far fa-file-alt "></i>
                                <p class="users">{{item.registration}}</p>
                            </div>                        
    
                        </div>
    
                    </div>
    
                    <div class="headerLista">
                        <h3 class="title" *ngIf="i === 0">Conta</h3>

                        <div class="container-dados">
    
                            <div class="listUsers__grid">
                                <i class="far fa-file-alt "></i>
                                <p class="users">{{acc.account.number}}</p>
                            </div>
                            <div class="listUsers__grid">
                                <i class="far fa-address-card "></i>
                                <p class="users" *ngIf="acc.profile == 'Holder'">Titular</p>
                                <p class="users" *ngIf="acc.profile == 'Dependent'">Dependente</p>
                            </div>
                            <div class="listUsers__grid">
                                <i class="far fa-credit-card "></i>
                                <p class="users">{{acc.cardNumber}}</p>
                            </div>
                            <div class="listUsers__grid">
                                <i class="far fa-bookmark "></i>
                                <p class="users" *ngIf="acc.status == 'Active'">Ativo</p>
                                <p class="users" *ngIf="acc.status == 'NotHavingCard'">Sem cartão</p>
                            </div>                           
    
                        </div>
    
                    </div>
    
                    <div class="headerLista">
                        <h3 class="title" *ngIf="i === 0">Limite de Crédito</h3>
    
                        <div class="container-dados">
    
                            <div class="listUsers__grid">
                                <i class="far fa-flag"></i>
                                <p class="users">{{acc.creditCardLimit | currency}}</p>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="headerLista rigth">
                        <h3 class="title" *ngIf="i === 0">Ações</h3>
    
                        <div class="container-dados">
                            <div class="listUsers__grid">
                                <i class="fas fa-user-edit"></i>
                                <p class="users" (click)="updateLimit(item._id,acc._id)">Atualizar Limite</p>
                            </div>
    
                            <div class="listUsers__grid" *ngIf="acc.profile == 'Dependent'">
                                <i class="fas fa-times-circle"></i>
                                <p class="users" (click)="removeAccount(item._id, acc._id)">Excluir Dependente</p>
                            </div>
                        </div>
    
                    </div>
    
                    <div class="line"></div>
                </div>

            </div>

            <div class="line-header next"></div>
        </div>
    </div>
</div>