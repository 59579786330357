import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { RequestsService } from '../../../services/requests/requests.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class RequestEditComponent implements OnInit {
  editRequestForm: FormGroup;

  constructor(
    private requestService: RequestsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RequestEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    this.editRequestForm = this.fb.group({
      type: new FormControl(this.data.type, [Validators.required]),
      status: new FormControl(this.data.status, [Validators.required]),
      reason: new FormControl(this.data.reason, [Validators.required]),
      value: new FormControl(this.data.value, [Validators.required]),
      feedback: new FormControl(this.data.feedback, [Validators.required])
    });

    this.editRequestForm.controls['type'].disable({ onlySelf: true });
    this.editRequestForm.controls['status'].disable({ onlySelf: true });
    this.editRequestForm.controls['reason'].disable({ onlySelf: true });
    this.editRequestForm.controls['value'].disable({ onlySelf: true });

    if (this.editRequestForm.controls['status'].value != 'Solicitado')
      this.editRequestForm.controls['feedback'].disable({ onlySelf: true });

  }

  cancel(): void {
    this.dialogRef.close();
  }

  update(status): void {
    if (!this.editRequestForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.editRequestForm.markAllAsTouched();

      return;
    }

    debugger;
    let paylod = this.editRequestForm.value;
    let model = {
      _id: this.data._id,
      type: this.data.type,
      status: status,
      feedback: paylod.feedback,
      reason: this.data.reason,
      value: this.data.value,
      cooperativeCustomer: {
        _id: this.data.cooperativeCustomer._id,
        accountId: this.data.account._id
      }
    }

    this.requestService.edit(model).subscribe((json) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Atualização realizada com sucesso.'
      }).then(() => this.dialogRef.close());
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

}
