import { Component, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmptyComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
    if (this.router.url === '/') {
      this.router.navigate(['/']);
    }
  }

}
