import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP } from '../../../app.config';
import { stringify } from 'query-string';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CooperativesService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.cookieService.get('token')
  });

  listCooperatives(payload) {
    let queryString = stringify(payload);

    if(queryString) {
      queryString = '?' + queryString;
    }

    return this.http.get<any>(APP.URL_BASE + '/cooperativecustomers' + queryString, { headers: this.headers });
  }

  get(cooperativeId: String) {

    return this.http.get(APP.URL_BASE + '/cooperativecustomers/' + cooperativeId, { headers: this.headers });
  }

  save(payload: any) {
    console.warn('cooperativecustomers save => ', payload);

    return this.http.post(APP.URL_BASE + '/cooperativecustomers', payload, { headers: this.headers });
  }

  edit(payload: any) {
    console.warn('cooperativecustomers edit => ', payload);

    return this.http.put(APP.URL_BASE + '/cooperativecustomers', payload, { headers: this.headers });
  }

  editLimit(cooperativeId: String, accountId: String) {
    console.log('cooperativeId => ', cooperativeId);
    console.log('accountId => ', accountId);

    return this.http.put(APP.URL_BASE + '/cooperativecustomers/' + cooperativeId + '/' + accountId, {}, { headers: this.headers });
  }

  remove(cooperativeId: any) {
    console.warn('cooperativecustomers remove => ', cooperativeId);

    return this.http.delete(APP.URL_BASE + '/cooperativecustomers/' + cooperativeId, { headers: this.headers });
  }

}
