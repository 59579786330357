import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarComponent } from '../../../components/sidebar/sidebar.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { EstablishmentsService } from '../../../services/establishments/establishments.service';
import { PostalcodeService } from '../../../services/postalcode/postalcode.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EstablishmentEditComponent implements OnInit {

  establishmentForm: FormGroup;
  establishmentId: String;

  constructor(
    private fb: FormBuilder,
    private establishmentsService: EstablishmentsService,
    private postalcodeService: PostalcodeService,
    private route: ActivatedRoute,
    private router: Router,
    private sidebarComponent: SidebarComponent,
  ) { }

  ngOnInit(): void {

    let url = this.router.url.split('/');
    this.sidebarComponent.getBarName(url[1]);

    this.establishmentForm = this.fb.group({
      code: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      cnpj: new FormControl('', [Validators.required]),
      responsible: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl('', [Validators.required]),
      addressType: new FormControl('', [Validators.required]),
      addressName: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      logradouro: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(''),
      neighborhood: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required])
    });

    this.establishmentId = this.route.snapshot.paramMap.get("id");
    this.establishmentsService.get(this.establishmentId).subscribe((data) => { 
      console.log(data);
      
      this.establishmentForm.controls['code'].setValue(data['code']);
      this.establishmentForm.controls['name'].setValue(data['name']);
      this.establishmentForm.controls['cnpj'].setValue(data['cnpj']);
      this.establishmentForm.controls['responsible'].setValue(data['responsible']);
      this.establishmentForm.controls['email'].setValue(data['email']);
      this.establishmentForm.controls['cellphone'].setValue(data['cellphone']);
      this.establishmentForm.controls['addressType'].setValue(data['address']['type']);
      this.establishmentForm.controls['addressName'].setValue(data['address']['name']);
      this.establishmentForm.controls['postalCode'].setValue(data['address']['postalCode']);
      this.establishmentForm.controls['logradouro'].setValue(data['address']['address']);
      this.establishmentForm.controls['number'].setValue(data['address']['number']);
      this.establishmentForm.controls['complement'].setValue(data['address']['complement']);
      this.establishmentForm.controls['neighborhood'].setValue(data['address']['neighborhood']);
      this.establishmentForm.controls['city'].setValue(data['address']['city']);
      this.establishmentForm.controls['state'].setValue(data['address']['state']);

    });

  }

  edit() {
    if (!this.establishmentForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.establishmentForm.markAllAsTouched();

      return;
    }

    let model = this.establishmentForm.value;

    let payload = {
      _id: this.establishmentId,
      code: model.code,
      name: model.name,
      cnpj: model.cnpj,
      responsible: model.responsible,
      email: model.email,
      cellphone: model.cellphone,
      address: {
        name: model.addressName,
        type: model.addressType,
        address: model.logradouro,
        number: model.number,
        complement: model.complement,
        neighborhood: model.neighborhood,
        postalCode: model.postalCode,
        state: model.state,
        city: model.city
      }
    }

    this.establishmentsService.edit(payload).subscribe((data) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Estabelecimento atualizado com sucesso.'
      }).then(() => window.location.href = "/estabelecimentos");
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

  searchCep() {
    let postalCode = this.establishmentForm.value.postalCode;

    if (postalCode.length != 8)
      return;

    this.postalcodeService.getAddress(postalCode).subscribe((data) => {

      this.establishmentForm.controls['logradouro'].setValue(data['logradouro']);
      this.establishmentForm.controls['neighborhood'].setValue(data['bairro']);
      this.establishmentForm.controls['city'].setValue(data['localidade']);
      this.establishmentForm.controls['state'].setValue(data['uf']);

    });
  }

}
