import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResetPasswordService } from '../../services/authentication/reset-password.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  isSend: boolean;

  constructor(private router: Router, private resetPassword: ResetPasswordService) { }

  ngOnInit(): void {
    this.isSend = false;
  }

  forgotPassword(model){
    console.warn(model);    
    this.isSend = true;

    this.resetPassword.resetPassword(model.username)
      .subscribe((data: any) => {
        console.warn(data);        
        this.router.navigate(['login']);
      }, (err) => {
        alert('Não foi possível se autenticar, tente novamente!');
      });

  }

}
