<h1 mat-dialog-title>Número da Conta: {{number}}</h1>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="accountForm">
        <mat-form-field class="example-full-width">
            <mat-label>Limite de Crédito (R$) </mat-label>
            <input matInput placeholder="" formControlName="creditLimit" name="creditLimit" mask="separator.2" thousandSeparator="." precision="2"/>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="save()">Salvar</button>
</div>