import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { EstablishmentsService } from '../../../services/establishments/establishments.service';
import { PostalcodeService } from '../../../services/postalcode/postalcode.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class EstablishmentNewComponent implements OnInit {
  establishmentForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private establishmentsService: EstablishmentsService,
    private postalcodeService: PostalcodeService
  ) { }

  ngOnInit(): void {

    this.establishmentForm = this.fb.group({
      code: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      cnpj: new FormControl('', [Validators.required]),
      responsible: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl('', [Validators.required]),
      addressType: new FormControl('', [Validators.required]),
      addressName: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      logradouro: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(''),
      neighborhood: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required])
    });

  }

  searchCep() {
    let postalCode = this.establishmentForm.value.postalCode;

    if (postalCode.length != 8)
      return;

    this.postalcodeService.getAddress(postalCode).subscribe((data) => {

      this.establishmentForm.controls['logradouro'].setValue(data['logradouro']);
      this.establishmentForm.controls['neighborhood'].setValue(data['bairro']);
      this.establishmentForm.controls['city'].setValue(data['localidade']);
      this.establishmentForm.controls['state'].setValue(data['uf']);

    });
  }

  save() {
    if (!this.establishmentForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Formulário inválido! Favor, preencher corretamente.'
      });

      this.establishmentForm.markAllAsTouched();

      return;
    }

    let model = this.establishmentForm.value;

    let payload = {
      code: model.code,
      name: model.name,
      cnpj: model.cnpj,
      responsible: model.responsible,
      email: model.email,
      cellphone: model.cellphone,
      address: {
        name: model.addressName,
        type: model.addressType,
        address: model.logradouro,
        number: model.number,
        complement: model.complement,
        neighborhood: model.neighborhood,
        postalCode: model.postalCode,
        state: model.state,
        city: model.city
      }
    }

    this.establishmentsService.save(payload).subscribe((data) => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Estabelecimento cadastrado com sucesso.'
      }).then(() => window.location.href = "/estabelecimentos");
    }, (error) => {
      console.warn('ERROR >> ', error);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro inesperado!'
      });
    });
  }

}
