<h1 mat-dialog-title>Conta Cooperado: {{data.number}}</h1>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="editAccountForm">
        <mat-form-field class="example-full-width">
            <mat-label>Limite de Crédito (R$) </mat-label>
            <input matInput placeholder="" formControlName="creditLimit" name="creditLimit" mask="separator.2" thousandSeparator="."/>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="update()">Atualizar Limite</button>
</div>