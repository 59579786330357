import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeesService } from '../../../services/employees/employees.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class EmployeeListComponent implements OnInit {
  employees = [];
  limit = 10;
  skip = 0;
  searchForm: FormGroup;

  constructor(private fb: FormBuilder, private employeesService: EmployeesService) { }

  ngOnInit(): void {
    this.employees = [];
    this.searchForm = this.fb.group({
      search: new FormControl('')
    });

    this.employeesService.listEmployees({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.employees = data});
  }

  find() {
    let search = this.searchForm.value.search;

    if (!search) {
      this.employeesService.listEmployees({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.employees = data});
      return;
    }

    this.employeesService.listEmployees({ name: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { 
      this.employees = data;
      
      if(this.employees.length === 0) {
        this.employeesService.listEmployees({ cpf: search, isEnable: true, skip: this.skip, limit: this.limit }).subscribe(payload => { this.employees = payload });
      }

    });
  }

  onScrollDown() {
    this.skip += this.limit;

    this.employeesService.listEmployees({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { 
      this.employees = this.employees.concat(data) 
    });
  }

  removeEmployee(employeeId){
    console.warn('remover funcionário ', employeeId);

    this.employeesService.remove(employeeId).subscribe(data => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Funcionário excluído com sucesso.'
      }).then(() => window.location.reload());
    })
  }

}
