<h1 mat-dialog-title>Conta Cooperado: {{data.account.number}}</h1>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="editRequestForm">
        <mat-form-field class="example-full-width">
            <mat-label>Tipo </mat-label>
            <input matInput placeholder="" formControlName="type" name="type" />
        </mat-form-field>
        <br />

        <mat-form-field class="example-full-width">
            <mat-label>Situação </mat-label>
            <input matInput placeholder="" formControlName="status" name="status" />
        </mat-form-field>
        <br />

        <mat-form-field class="example-full-width">
            <mat-label>Motivo </mat-label>
            <input matInput placeholder="" formControlName="reason" name="reason" />
        </mat-form-field>
        <br />

        <mat-form-field class="example-full-width">
            <mat-label>Valor </mat-label>
            <input matInput placeholder="" formControlName="value" name="value" mask="separator.2" thousandSeparator="."/>
        </mat-form-field>
        <br />

        <mat-form-field class="example-full-width-textarea">
            <mat-label>Resposta </mat-label>
            <textarea matInput placeholder="" formControlName="feedback" name="feedback"></textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancelar</button>
    <button mat-raised-button *ngIf="editRequestForm.controls['status'].value == 'Solicitado'" color="secundary" (click)="update('Reprovado')">Reprovar</button>
    <button mat-raised-button *ngIf="editRequestForm.controls['status'].value == 'Solicitado'" color="primary" (click)="update('Aprovado')">Aprovar</button>
</div>