import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { RequestEditComponent } from '../edit/edit.component';
import { RequestsService } from '../../../services/requests/requests.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class RequestListComponent implements OnInit {
  requests = [];
  limit = 10;
  skip = 0;

  constructor(
    private requestsService: RequestsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.requests = [];
   
    this.requestsService.listRequests({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { this.requests = data });
  }

  onScrollDown() {
    this.skip += this.limit;

    this.requestsService.listRequests({ isEnable: true, skip: this.skip, limit: this.limit }).subscribe(data => { 
      this.requests = this.requests.concat(data) 
    });
  }

  updateRequest(requestId): void {

    this.requestsService.get(requestId).subscribe(json => {

      const dialogRef = this.dialog.open(RequestEditComponent, { width: "100%", maxWidth: "500px", minWidth: "150px", data: json });

      dialogRef.afterClosed().subscribe(result => {
        window.location.reload();
      });
    });
  }
  
}
