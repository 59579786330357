<div class="section">
    <div class="section__header">
        <div class="container-header" [formGroup]="searchForm">
            <div class="buscaHeader styleCampos">
                <input type="text" id="txtBuscaHeader" formControlName="search" name="search" placeholder="Pesquisa por: Nome ou CPF" (keyup.enter)="find()" />
                <i class="fas fa-search" id="img-search" (click)="find()"></i>
            </div>

            <!-- <div class="exportarDados styleCampos">
                <p class="expDados">Exportar dados</p>
                <i class="fas fa-chevron-down"></i>
            </div> -->
        </div>
    </div>

    <div class="listaCooperados" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown()" [scrollWindow]="false">
        <div class="line-header"></div>
        <div *ngFor="let item of employees; let i = index">

            <div class="containerListaCooperados">

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Profissional</h3>

                    <div class="container-dados">
                        <div class="listUsers__grid">
                            <i class="fas fa-user"></i>
                            <p class="users">{{item.name}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="fas fa-phone-volume"></i>
                            <p class="users tel">{{item.cellphone}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="fas fa-envelope"></i>
                            <p class="users letters">{{item.email}}</p>
                        </div>

                        <div class="listUsers__grid">
                            <i class="far fa-list-alt"></i>
                            <p class="users letters">{{item.profile}}</p>
                        </div>
                    </div>

                </div>

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">CPF</h3>

                    <div class="container-dados">
                        <div class="listUsers__grid">
                            <i class="far fa-file-alt "></i>
                            <p class="users">{{item.cpf}}</p>
                        </div>
                    </div>

                </div>

                <div class="headerLista">
                    <h3 class="title" *ngIf="i === 0">Endereço</h3>

                    <div class="container-dados">

                        <div class="listUsers__grid" *ngIf="item.establishment">
                            <i class="far fa-flag"></i>
                            <p class="users">{{item.establishment.name}}</p>
                        </div>

                        <div class="listUsers__grid" *ngIf="item.address">
                            <i class="fas fa-map-marker-alt"></i>
                            <p class="users">{{item.address.address}}, {{item.address.number}}</p>
                        </div>

                        <div class="listUsers__grid" *ngIf="item.address">
                            <p class="users line-up">{{item.address.neighborhood}} {{item.address.complement}}</p>
                        </div>

                        <div class="listUsers__grid" *ngIf="item.address">
                            <p class="users line-up">{{item.address.city}}/{{item.address.state}} -
                                {{item.address.postalCode}}</p>
                        </div>
                    </div>
                </div>

                <div class="headerLista rigth">
                    <h3 class="title" *ngIf="i === 0">Ações</h3>

                    <div class="container-dados">
                        <div class="listUsers__grid">
                            <i class="fas fa-user-edit"></i>
                            <a class="users edit" [routerLink]="['/funcionario-editar', item._id]">Editar dados</a>
                        </div>

                        <div class="listUsers__grid">
                            <i class="fas fa-times-circle"></i>
                            <p class="users" (click)="removeEmployee(item._id)">Excluir Funcionário</p>
                        </div>
                    </div>

                </div>

                <div class="line"></div>
            </div>

            <div class="line-header next"></div>
        </div>
    </div>
</div>