<h1 mat-dialog-title>Conta Cooperado: {{data.number}}</h1>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="cooperativeAccountForm">
        <mat-form-field class="example-full-width">
            <mat-label>Perfil * </mat-label>
            <select matNativeControl formControlName="profile" name="profile" id="profile" (change)='changedProfile()'
                [ngClass]="{alert: cooperativeAccountForm.controls['profile'].touched && cooperativeAccountForm.controls['profile'].invalid}">
                <option value="" disabled="disabled" selected="selected">Escolha a opção</option>
                <option [disabled]="data.cooperative" value="Holder">Administrador</option>
                <option value="Dependent">Dependente</option>
            </select>
        </mat-form-field>
        <br />

        <mat-form-field class="example-full-width">
            <mat-label>Tipo de Dependente </mat-label>
            <select matNativeControl formControlName="dependentType" name="dependentType" id="dependentType">
                <option value="" disabled="disabled" selected="selected">Escolha a opção</option>
                <option [disabled]="cooperativeAccountForm.controls['profile'].value == 'Holder'" value="Procurator">Procurador</option>
                <option [disabled]="cooperativeAccountForm.controls['profile'].value == 'Holder'" value="Common">Comum</option>
            </select>
        </mat-form-field>
        <br />

        <mat-form-field class="example-full-width">
            <mat-label>Limite do Cartão </mat-label>
            <input matInput placeholder="" formControlName="creditCardLimit" name="creditCardLimit" mask="separator.2" thousandSeparator="." />
        </mat-form-field>
        <br />
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Confirmar</button>
</div>